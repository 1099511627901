import { Avatar, Input, Button, Alert } from "@material-tailwind/react";
import { Footer } from "./Footer";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useParams } from "react-router-dom";

export function Admin() {
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const { userId } = useParams();
  const [alertArray, setAlertArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const API_URL = "https://dev.wisitcard.com";
  const API_PORT = 8083;
  const [api_url, setApiUrl] = useState("");
  const [api_port, setApiPort] = useState("");

  useEffect(() => {
    setIsLoading(true);
    setUsername(userId);
    setApiPort(API_PORT);
    setApiUrl(API_URL);
    const fetchData = async () => {
      try {
        const tokenResponse = await fetch(
          `${API_URL}:${API_PORT}/authentication`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: "DlkSoftPass..159357",
              username: "dileksoft",
            }),
          }
        );

        if (!tokenResponse.ok) {
          throw new Error("Token alınırken bir hata oluştu");
        }

        const tokenData = await tokenResponse.json();
        const token = tokenData.jwt;
        setToken(token);

        setIsLoading(false);
      } catch (error) {
        console.error("API'den veri alınırken bir hata oluştu:", error);
      }
    };

    fetchData();
  }, []);

  const handleLoginCheck = async () => {
    try {
      const operationResponse = await fetch(
        `${API_URL}:${API_PORT}/saveuser/checkUserNamePassword`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dijituneId: username,
            userPassword: password,
          }),
        }
      );
      const response = await operationResponse.json();
      if (
        response.dijituneId !== null ||
        response.dijituneId !== "" ||
        response.dijituneId !== undefined ||
        response.dijituneId !== "undefined" ||
        response.dijituneId !== "null" ||
        response.dijituneId !== "NULL" ||
        response.dijituneId !== "KULLANICI ADI VEYA ŞİFRE HATALI"
      ) {
        setResponseData(response);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleInputChange = (key, value) => {
    setResponseData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const saveResponse = await fetch(
        `${API_URL}:${API_PORT}/saveuser/update`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(responseData),
        }
      );

      if (!saveResponse.ok) {
        handleAlert("error");
        return;
      }

      const userData = await saveResponse.json();
      handleAlert("success");
      setResponseData(userData);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setIsLoading(false);
  };

  const handleAlert = (status) => {
    if (status === "success") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarılı",
        color: "green",
      });
    } else if (status === "error") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarısız",
        color: "red",
      });
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Loading />
        ) : !loggedIn ? (
          <div className="flex items-center justify-center w-screen px-12 h-screen">
            <div className="relative w-full shadow-2xl rounded-xl py-16 px-4">
              <div className="container mx-auto flex flex-col gap-4">
                <Input
                  label="Kullanıcı Adı"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <Input
                  label="Şifre"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                  value={password}
                />
                <Button
                  color="blue"
                  onClick={handleLoginCheck} // Remove the parentheses from handleLogin()
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                >
                  GİRİŞ YAP
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="relative bg-white  block h-[50vh]">
              <div
                className="bg-profile-background absolute top-0 h-full w-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(${
                    api_url + ":" + api_port + "/" + responseData.thumbnailName
                  })`,
                }}
              />

              <div className="absolute  top-0 h-full w-full bg-black/40 bg-cover bg-center" />
            </section>
            <section className="relative bg-gray-100 py-16 px-4">
              <div className="container mx-auto">
                <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
                  <div className="px-6 flex justify-center">
                    <div className="w-full flex justify-center lg:w-3/12">
                      <div className="relative -mt-20 w-40">
                        <Avatar
                          src={
                            api_url +
                            ":" +
                            api_port +
                            "/" +
                            responseData.fileName
                          }
                          alt="Profile picture"
                          variant="circular"
                          className="h-full w-full shadow-xl"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-16 flex flex-col items-center">
                    <div className="sm:w-full w-full md:w-4/5 xl:w-2/3 2xl:w-2/3 flex flex-col gap-4 px-6">
                      <Alert
                        className="shadow-xl text-xl"
                        color={alertArray.color}
                        open={open}
                        onClose={() => setOpen(false)}
                      >
                        {alertArray.text}
                      </Alert>
                      <Input
                        color="blue"
                        label="İsim"
                        value={responseData.firstName}
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Soyisim"
                        value={responseData.lastName}
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Telefon"
                        value={responseData.phoneNumber}
                        onChange={(e) =>
                          handleInputChange("phoneNumber", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Açıklama"
                        value={responseData.description}
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="İş"
                        value={responseData.userJob}
                        onChange={(e) =>
                          handleInputChange("userJob", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Mail"
                        value={responseData.userEmail}
                        onChange={(e) =>
                          handleInputChange("userEmail", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Şehir"
                        value={responseData.userCity}
                        onChange={(e) =>
                          handleInputChange("userCity", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 1 Adı"
                        value={responseData.userIbanName1}
                        onChange={(e) =>
                          handleInputChange("userIbanName1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Banka 1"
                        value={responseData.userBank1}
                        onChange={(e) =>
                          handleInputChange("userBank1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 1"
                        value={responseData.userIban1}
                        onChange={(e) =>
                          handleInputChange("userIban1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 2 Adı"
                        value={responseData.userIbanName2}
                        onChange={(e) =>
                          handleInputChange("userIbanName2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Banka 2"
                        value={responseData.userBank2}
                        onChange={(e) =>
                          handleInputChange("userBank2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 2"
                        value={responseData.userIban2}
                        onChange={(e) =>
                          handleInputChange("userIban2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Web Site"
                        value={responseData.userWebsite}
                        onChange={(e) =>
                          handleInputChange("userWebsite", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Instagram"
                        value={responseData.userInstagram}
                        onChange={(e) =>
                          handleInputChange("userInstagram", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Facebook"
                        value={responseData.userFacebook}
                        onChange={(e) =>
                          handleInputChange("userFacebook", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Twitter"
                        value={responseData.userTwitter}
                        onChange={(e) =>
                          handleInputChange("userTwitter", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Linkedin"
                        value={responseData.userLinkedin}
                        onChange={(e) =>
                          handleInputChange("userLinkedin", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Whatsapp"
                        value={responseData.userWhatsapp}
                        onChange={(e) =>
                          handleInputChange("userWhatsapp", e.target.value)
                        }
                      />
                      <Button color="blue" onClick={handleSave}>
                        Kaydet
                      </Button>
                    </div>
                  </div>
                  <div className="bg-blue-gray-50/50 rounded-3xl">
                    <Footer />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default Admin;
