import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import Admin from "./Components/Admin";
import AdderAdmin from "./Components/AdminAddUser";
import Newuser from "./Components/Newuser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/:userId" element={<Home />} />
        <Route path="/admin/:userId" element={<Admin />} />
        <Route path="/addUser" element={<AdderAdmin />} />
        <Route path="/newuser" element={<Newuser />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
