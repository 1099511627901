import { Input, Button, Alert } from "@material-tailwind/react";
import { Footer } from "./Footer";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";

export function Newuser() {
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const [alertArray, setAlertArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [personelId, setPersonelId] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("+905");
  const [description, setDescription] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userJob, setUserJob] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [userQr, setUserQr] = useState("https://user.wisitcard.com/");
  const [userWhatsapp, setUserWhatsapp] = useState(null);
  const [userTelegram, setUserTelegram] = useState(null);
  const [userLinkedin, setUserLinkedin] = useState(null);
  const [userInstagram, setUserInstagram] = useState(null);
  const [userFacebook, setUserFacebook] = useState(null);
  const [userFoursquare, setUserFoursquare] = useState(null);
  const [userWebsite, setUserWebsite] = useState(null);
  const [userTwitter, setUserTwitter] = useState(null);
  const [userSnapchat, setUserSnapchat] = useState(null);
  const [userTiktok, setUserTiktok] = useState(null);
  const [userYoutube, setUserYoutube] = useState(null);
  const [userCatalog, setUserCatalog] = useState(null);
  const [userHepsiburada, setUserHepsiburada] = useState(null);
  const [userTrendyol, setUserTrendyol] = useState(null);
  const [userSahibinden, setUserSahibinden] = useState(null);
  const [userGithub, setUserGithub] = useState(null);
  const [userIban1, setUserIban1] = useState(null);
  const [userIban2, setUserIban2] = useState(null);
  const [userBank1, setUserBank1] = useState(null);
  const [userBank2, setUserBank2] = useState(null);
  const [userIbanName1, setUserIbanName1] = useState(null);
  const [userIbanName2, setUserIbanName2] = useState(null);

  const API_URL = "https://dev.wisitcard.com";
  const API_PORT = 8083;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const tokenResponse = await fetch(
          `${API_URL}:${API_PORT}/authentication`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: "DlkSoftPass..159357",
              username: "dileksoft",
            }),
          }
        );

        if (!tokenResponse.ok) {
          throw new Error("Token alınırken bir hata oluştu");
        }

        const tokenData = await tokenResponse.json();
        const token = tokenData.jwt;
        setToken(token);

        setIsLoading(false);
      } catch (error) {
        console.error("API'den veri alınırken bir hata oluştu:", error);
      }
    };

    fetchData();
  }, []);

  const handleLoginCheck = () => {
    if (username === "dileksoft" && password === "admin") {
      setIsLoggedIn(true);
      setUsername("");
      setPassword("");
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const responseData = {
      dijituneId: personelId,
      firstName: firstName,
      lastName: lastName,
      userPassword: "wisitcard_@" + personelId,
      phoneNumber: phoneNumber,
      description: description,
      userCity: userCity,
      userEmail: userEmail,
      userJob: userJob,
      userLocation: userLocation,
      userQr: userQr,
      userWhatsapp: userWhatsapp,
      userTelegram: userTelegram,
      userLinkedin: userLinkedin,
      userInstagram: userInstagram,
      userFacebook: userFacebook,
      userFoursquare: userFoursquare,
      userWebsite: userWebsite,
      userTwitter: userTwitter,
      userSnapchat: userSnapchat,
      userTiktok: userTiktok,
      userYoutube: userYoutube,
      userCatalog: userCatalog,
      userHepsiburada: userHepsiburada,
      userTrendyol: userTrendyol,
      userSahibinden: userSahibinden,
      userGithub: userGithub,
      userIban1: userIban1,
      userIban2: userIban2,
      userIban3: null,
      userBank1: userBank1,
      userBank2: userBank2,
      userBank3: null,
      userIbanName1: userIbanName1,
      userIbanName2: userIbanName2,
      userIbanName3: null,
      photoPath: "../img/userimages/" + personelId + ".png",
      thumbnailPath: "../img/userimages/background-3.png",
      defaultLanguage: "tr",
    };
    try {
      const saveResponse = await fetch(`${API_URL}:${API_PORT}/saveuser/save`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(responseData),
      });

      if (!saveResponse.ok) {
        handleAlert("error");
        return;
      }

      const userData = await saveResponse.json();
      console.log(userData);
      handleAlert("success");
      setIsLoggedIn(true);
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setIsLoading(false);
  };

  const handleAlert = (status) => {
    if (status === "success") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarılı",
        color: "green",
      });
    } else if (status === "error") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarısız",
        color: "red",
      });
    }
  };
  const getColor = (inputValue) => {
    const length = inputValue.length;

    if (length < 13) {
      return "red"; // If length is less than 13, set color to red
    } else if (length > 13) {
      return "red"; // If length is greater than 13, set color to red
    } else {
      return "green"; // If length is equal to 13, set color to green
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Loading />
        ) : !loggedIn ? (
          <div className="flex items-center justify-center w-screen px-12 h-screen">
            <div className="relative w-full shadow-2xl rounded-xl py-16 px-4">
              <div className="container mx-auto flex flex-col gap-4">
                <Input
                  label="Kullanıcı Adı"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <Input
                  label="Şifre"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                  value={password}
                />
                <Button
                  color="blue"
                  onClick={handleLoginCheck} // Remove the parentheses from handleLogin()
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                >
                  GİRİŞ YAP
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="relative bg-white  block h-[50vh]">
              <div
                className="bg-profile-background absolute top-0 h-full w-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(../img/userimages/background-3.png)`,
                }}
              />
              <div className="absolute  top-0 h-full w-full bg-black/40 bg-cover bg-center" />
            </section>
            <section className="relative bg-gray-100 py-16 px-4">
              <div className="container mx-auto">
                <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
                  <div className="mt-16 flex flex-col items-center">
                    <div className="sm:w-full w-full md:w-4/5 xl:w-2/3 2xl:w-2/3 flex flex-col gap-4 px-6">
                      <Alert
                        className="shadow-xl text-xl"
                        color={alertArray.color}
                        open={open}
                        onClose={() => setOpen(false)}
                      >
                        {alertArray.text}
                      </Alert>
                      <Input
                        color="blue"
                        label="id"
                        value={personelId}
                        onChange={(e) => setPersonelId(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Ad"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Soyad"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />

                      <Input
                        color={getColor(phoneNumber)}
                        label="Telefon"
                        min={13}
                        max={13}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Açıklama"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Şehir"
                        value={userCity}
                        onChange={(e) => setUserCity(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Meslek"
                        value={userJob}
                        onChange={(e) => setUserJob(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Konum"
                        value={userLocation}
                        onChange={(e) => setUserLocation(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="QR"
                        value={userQr}
                        onChange={(e) => setUserQr(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Whatsapp"
                        value={userWhatsapp}
                        onChange={(e) => setUserWhatsapp(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Telegram"
                        value={userTelegram}
                        onChange={(e) => setUserTelegram(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Linkedin"
                        value={userLinkedin}
                        onChange={(e) => setUserLinkedin(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Instagram"
                        value={userInstagram}
                        onChange={(e) => setUserInstagram(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Facebook"
                        value={userFacebook}
                        onChange={(e) => setUserFacebook(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Foursquare"
                        value={userFoursquare}
                        onChange={(e) => setUserFoursquare(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Website"
                        value={userWebsite}
                        onChange={(e) => setUserWebsite(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Twitter"
                        value={userTwitter}
                        onChange={(e) => setUserTwitter(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Snapchat"
                        value={userSnapchat}
                        onChange={(e) => setUserSnapchat(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Tiktok"
                        value={userTiktok}
                        onChange={(e) => setUserTiktok(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Youtube"
                        value={userYoutube}
                        onChange={(e) => setUserYoutube(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Katalog"
                        value={userCatalog}
                        onChange={(e) => setUserCatalog(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Hepsiburada"
                        value={userHepsiburada}
                        onChange={(e) => setUserHepsiburada(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Trendyol"
                        value={userTrendyol}
                        onChange={(e) => setUserTrendyol(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Sahibinden"
                        value={userSahibinden}
                        onChange={(e) => setUserSahibinden(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Github"
                        value={userGithub}
                        onChange={(e) => setUserGithub(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="IbanName1"
                        value={userIbanName1}
                        onChange={(e) => setUserIbanName1(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Iban1"
                        value={userIban1}
                        onChange={(e) => setUserIban1(e.target.value)}
                      />

                      <Input
                        color="blue"
                        label="Bank1"
                        value={userBank1}
                        onChange={(e) => setUserBank1(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="IbanName2"
                        value={userIbanName2}
                        onChange={(e) => setUserIbanName2(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Iban2"
                        value={userIban2}
                        onChange={(e) => setUserIban2(e.target.value)}
                      />
                      <Input
                        color="blue"
                        label="Bank2"
                        value={userBank2}
                        onChange={(e) => setUserBank2(e.target.value)}
                      />

                      <Button color="blue" onClick={handleSave}>
                        Kaydet
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Footer />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
}

export default Newuser;
