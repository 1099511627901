import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
export default function Loading() {
  return (
    <div>
      <div>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
      <div className="flex flex-col items-center justify-center h-screen">
        <CircularProgress />
        <div>
          <p className="mt-2">YÜKLENİYOR...</p>
        </div>
      </div>
    </div>
  );
}
