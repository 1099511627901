import { Avatar, Input, Button, Alert } from "@material-tailwind/react";
import { Footer } from "./Footer";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useParams } from "react-router-dom";


export function Admin() {
  const [responseData, setResponseData] = useState({});
  const [wisitcardId, setWisitcardId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [token, setToken] = useState("");
  const { userId } = useParams();
  const [alertArray, setAlertArray] = useState([]);
  const [open, setOpen] = React.useState(false);
  const API_URL = "https://dev.wisitcard.com";
  const API_PORT = 8083;
  const [api_url, setApiUrl] = useState("");
  const [api_port, setApiPort] = useState("");
  const [imageData, setImageData] = useState(null);
  const [onImageSelect, setOnImageSelect] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [saveImage, setSaveImage] = useState(null);


 

  const handleInputChange = (key, value) => {
    if (key === "dijituneId") {
      setFormData(prevData => ({
        ...prevData,
        [key]: value,
        userQr: `https://user.wisitcard.com/${value}`, // dijituneId'yi userQr'ye ekleyin
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  const [formData, setFormData] = useState({
    dijituneId: null,
    userPassword: null,
    firstName: null,
    lastName: null,
    thumbnailPath: null,
    description: null,
    phoneNumber: null,
    phoneNumber2: null,
    userEmail: null,
    userCity: null,
    userJob: null,
    userTelegram: null,
    userLinkedin: null,
    userInstagram: null,
    userWebsite: null,
    userWhatsapp: null,
    userCatolog: null,
    userHepsiburada: null,
    userTrendyol: null,
    userSahibinden: null,
    userYoutube: null,
    userFacebook: null,
    userTwitter: null,
    userLocation: null,
    userFoursquare: null,
    userTiktok: null,
    userSnapchat: null,
    userIbanName1: null,
    userIbanName2: null,
    userIbanName3: null,
    userIban1: null,
    userIban2: null,
    userIban3: null,
    userBank1: null,
    userBank2: null,
    userBank3: null,
    userQr: null,
    defaultLanguage: "tr",
    userGithub: null,
    imageData: null,
  });

  useEffect(() => {
   

    setIsLoading(true);
    setUsername(userId);
    setApiPort(API_PORT);
    setApiUrl(API_URL);
    const fetchData = async () => {
      try {
        const tokenResponse = await fetch(
          `${API_URL}:${API_PORT}/authentication`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: "DlkSoftPass..159357",
              username: "dileksoft",
            }),
          }
        );

        if (!tokenResponse.ok) {
          throw new Error("Token alınırken bir hata oluştu");
        }

        const tokenData = await tokenResponse.json();
        const token = tokenData.jwt;
        setToken(token);

        setIsLoading(false);
      } catch (error) {
        console.error("API'den veri alınırken bir hata oluştu:", error);
      }
    };

    fetchData();
  }, []);

  const handleLoginCheck = async () => {
    try {
      const operationResponse = await fetch(
        `${API_URL}:${API_PORT}/saveuser/checkUserNamePassword`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dijituneId: username,
            userPassword: password,
          }),
        }
      );

      if (!operationResponse.ok) {
        handleAlert("errorlogin")
        console.error("Failed to perform login check");
        return;
      }

      const userData = await operationResponse.json();
      console.log(userData);
      setResponseData(userData);
      handleAlert("successlogin")
      setIsLoggedIn(true);
      // Clear input values after successful login
      setPassword("");
      setUsername("");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };



  const handleSave = async () => {

    
    console.log("kayıt")
    console.log(formData)

    const newUser = {
      ...formData,
      imageData: onImageSelect, // wisitcardId değerini formData'daki dijituneId'e atıyoruz
    };
    console.log(newUser)
   // Form alanlarını kontrol et
   if (formData.dijituneId===null || formData.firstName===null|| formData.userPassword===null  || formData.lastName===null || formData.phone===null || formData.email===null || formData.city===null || formData.location===null) {
    alert("Lütfen tüm alanları doldurun.");
    setIsLoading(false);
    return;
  }

    
    try {
      const saveResponse = await fetch(
        `${API_URL}:${API_PORT}/saveuser/save`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUser),
        }
      );

      if (!saveResponse.ok) {
        handleAlert("error");
        return;
      }

      const userData = await saveResponse.json();
      console.log(userData);
      handleAlert("success");
      setResponseData({});
      // Clear input values after successful save
    
    } catch (error) {
      console.error("An error occurred:", error);
    }
    
  };

  const handleAlert = (status) => {
    if (status === "success") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarılı, sayfa güncellendi", // Başarılı işlem mesajı güncellendi
        color: "green",
      });
    } else if (status === "error") {
      setOpen(true);
      setAlertArray({
        text: "Kayıt başarısız",
        color: "red",
      });
    }else if (status === "successlogin") {
      setOpen(true);
      setAlertArray({
        text: "Giriş Başarılı",
        color: "green",
      });
    }else if (status === "errorlogin") {
      setOpen(true);
      setAlertArray({
        text: "Giriş başarısız",
        color: "red",
      });
    }
  };

const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    // Resmi base64'e dönüştür
    reader.onloadend = () => {
      const base64String = reader.result;
      setImageData(base64String);
      onImageSelect(base64String); // Seçilen resmi parent bileşene gönder
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = reader.result;
        setSelectedImage(base64String);
        setOnImageSelect(base64String); 
  
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Loading />
        ) : !loggedIn ? (
          <div className="flex items-center justify-center w-screen px-12 h-screen">
            <div className="relative w-full shadow-2xl rounded-xl py-16 px-4">
              <div className="container mx-auto flex flex-col gap-4">
                <Input
                  label="Kullanıcı Adı"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <Input
                  label="Şifre"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                  value={password}
                />
                <Button
                  color="blue"
                  onClick={handleLoginCheck} // Remove the parentheses from handleLogin()
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                >
                  GİRİŞ YAP
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="relative bg-white  block h-[50vh]">
              <div
                className="bg-profile-background absolute top-0 h-full w-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(${responseData.thumbnailPath})`,
                }}
              />

              <div className="absolute  top-0 h-full w-full bg-black/40 bg-cover bg-center" />
            </section>
            <section className="relative bg-gray-100 py-16 px-4">
              <div className="container mx-auto">
                <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
                  <div className="px-6 flex justify-center">
                    <div className="w-full flex justify-center lg:w-3/12">
                      <div className="relative -mt-20 w-40">
                      <input type="file" accept="image/*" onChange={handleImageChange} />
                       {selectedImage && <img src={selectedImage} alt="Selected Image" />}
                       {selectedImage && <button onClick={saveImage}>Resmi Kaydet</button>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-16 flex flex-col items-center">
                    <div className="sm:w-full w-full md:w-4/5 xl:w-2/3 2xl:w-2/3 flex flex-col gap-4 px-6">
                      <Alert
                        className="shadow-xl text-xl"
                        color={alertArray.color}
                        open={open}
                        onClose={() => setOpen(false)}
                      >
                        {alertArray.text}
                      </Alert>
                      <Input
                        color="blue"
                        label="Kullanıcı Adı"
                        required
                        onChange={(e) =>
                          handleInputChange("dijituneId", e.target.value)
                          
                        }
                      />
                       <Input
                        color="blue"
                        label="Şifre"
                        defaultValue={"wisitcard_@"}
                        required
                        onChange={(e) =>
                          handleInputChange("userPassword", e.target.value)
                          
                        }
                      />
                      <Input
                        color="blue"
                        label="İsim"
                        required
                        onChange={(e) =>
                          handleInputChange("firstName", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Soyisim"
                        required
                        onChange={(e) =>
                          handleInputChange("lastName", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Telefon"
                        required
                        defaultValue={"+90"}
                        onChange={(e) =>
                          handleInputChange("phoneNumber", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Açıklama"
                        required
                        onChange={(e) =>
                          handleInputChange("description", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="İş"
                        required
                        onChange={(e) =>
                          handleInputChange("userJob", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Mail"
                        required
                        onChange={(e) =>
                          handleInputChange("userEmail", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Şehir"
                        required
                        onChange={(e) =>
                          handleInputChange("userCity", e.target.value)
                        }
                      />
                       <Input
                        color="blue"
                        label="Lokasyon"
                        required
                        onChange={(e) =>
                          handleInputChange("userLocation", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 1 Adı"
                       
                        onChange={(e) =>
                          handleInputChange("userIbanName1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Banka 1"
                       
                        onChange={(e) =>
                          handleInputChange("userBank1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 1"
                       
                        onChange={(e) =>
                          handleInputChange("userIban1", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 2 Adı"
                        
                        onChange={(e) =>
                          handleInputChange("userIbanName2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Banka 2"
                        
                        onChange={(e) =>
                          handleInputChange("userBank2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="IBAN 2"
                        
                        onChange={(e) =>
                          handleInputChange("userIban2", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Web Site"
                        
                        onChange={(e) =>
                          handleInputChange("userWebsite", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Instagram"
                        defaultValue={"https://www.instagram.com/"}
                        onChange={(e) =>
                          handleInputChange("userInstagram", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Facebook"
                        
                        onChange={(e) =>
                          handleInputChange("userFacebook", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Twitter"
                       
                        onChange={(e) =>
                          handleInputChange("userTwitter", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Linkedin"
                        
                        onChange={(e) =>
                          handleInputChange("userLinkedin", e.target.value)
                        }
                      />
                      <Input
                        color="blue"
                        label="Whatsapp"
                        defaultValue={"whatsapp://send?phone=+90"}
                        onChange={(e) =>
                          handleInputChange("userWhatsapp", e.target.value)
                        }
                      />
                      <Button color="blue" onClick={handleSave}>
                        Kaydet
                      </Button>
                    </div>
                  </div>
                  <div className="bg-blue-gray-50/50 rounded-3xl">
                    <Footer />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );

}

export default Admin;
