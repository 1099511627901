import {
  Avatar,
  Typography,
  Button,
  Select,
  Option,
} from "@material-tailwind/react";
import {
  MapPinIcon,
  UserIcon,
  PhoneIcon,
  WrenchScrewdriverIcon,
  ArrowUpTrayIcon,
  CreditCardIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import { Footer } from "./Footer";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { useParams } from "react-router-dom/dist";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";

function createVCard(no, firstname, lastname, Website, Email) {
  const vcard = `BEGIN:VCARD
VERSION:3.0
N:${lastname};${firstname};;;
FN:${firstname} ${lastname}
TEL;TYPE=CELL:${no}
EMAIL:${Email}
URL:${Website} 
END:VCARD`;
  return vcard;
}

export function Home() {
  const [responseData, setResponseData] = useState([]);
  const [defaultLang, setdefaultLang] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useParams();
  const API_URL = "https://dev.wisitcard.com";
  const API_PORT = 8083;
  const [api_url, setApiUrl] = useState("");
  const [api_port, setApiPort] = useState("");

  const handleSelectLang = (value) => {
    setdefaultLang(value); // Seçilen öğenin değerini durum değişkenini güncelle
    setSettingsModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setApiPort(API_PORT);
    setApiUrl(API_URL);
    fetch(
      `https://dev.wisitcard.com:8083/saveuser/findByLikeIgnoreCase?dijituneid=${userId}`
    )
      .then((response) => response.json())

      .then((data) => {
        if (data.length === 0) {
          setIsLoading(true); // Veri yükleme tamamlandığında loading durumu güncellenir
        } else {
          setResponseData(data);
          data.map((item) => {
            setdefaultLang(item.defaultLanguage);
            setIsLoading(false); // Veri yükleme tamamlandığında loading durumu güncellenir
          });
        }
      })
      .catch((error) => setIsLoading(true) + console.error(error))
      .then(() => {
        // Veri yükleme tamamlandığında loading durumu güncellenir
      });
  }, [userId]);

  const handleDownloadClick = (no, firstname, lastname, Website, Email) => {
    const vcardContent = createVCard(no, firstname, lastname, Website, Email);
    const vcardBlob = new Blob([vcardContent], { type: "text/vcard" });
    const vcardUrl = URL.createObjectURL(vcardBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = vcardUrl;
    downloadLink.download = firstname + " " + lastname + ".vcf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const [IbanModal, setIbanModal] = useState(false);
  const openIbanModal = () => {
    setIbanModal(true);
  };
  const closeIbanModal = () => {
    setIbanModal(false);
  };
  const handleCopy = (event, text) => {
    navigator.clipboard.writeText(text);
    toast.info("IBAN Kopyalandı", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleOpenMail = (event, email) => {
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  const [QRModal, setQRModal] = useState(false);
  const openQRModal = () => {
    setQRModal(true);
  };
  const closeQRModal = () => {
    setQRModal(false);
  };
  const [SettingsModal, setSettingsModal] = useState(false);
  const openSettingsModal = () => {
    setSettingsModal(true);
  };
  const closeSettingsModal = () => {
    setSettingsModal(false);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          responseData.map((item) => (
            <div>
              <section className="relative  block h-[50vh]">
                <div
                  className="bg-profile-background absolute top-0 h-full w-full bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${
                      api_url + ":" + api_port + "/" + item.thumbnailPath
                    })`,
                  }}
                />

                <div className="absolute  top-0 h-full w-full bg-black/40 bg-cover bg-center" />
              </section>
              <section className="relative bg-white py-16 px-4">
                <div className="container mx-auto">
                  <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-gray-200 shadow-xl shadow-gray-500/5">
                    <div className="px-6">
                      <div className="flex flex-wrap justify-center">
                        <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12">
                          <div className="relative">
                            <div className="-mt-20 w-40">
                              <Avatar
                                src={
                                  api_url +
                                  ":" +
                                  api_port +
                                  "/" +
                                  item?.fileName
                                }
                                alt="Profile picture"
                                variant="circular"
                                className="h-full w-full shadow-xl"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 text-center">
                        <div className="flex ml-1 flex-row items-center justify-center">
                          <Typography variant="h4" color="black">
                            {item.firstName} {item.lastName}
                          </Typography>
                          <CheckCircleIcon className="ml-1 h-7 text-blue-500" />
                        </div>

                        <div className="flex items-center justify-center gap-2">
                          <MapPinIcon className="-mt-px h-4 w-4 text-gray-600" />
                          <Typography className="font-medium text-sm md:text-lg text-gray-600">
                            {item.userCity}
                          </Typography>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <UserIcon className="-mt-px h-4 w-4 text-gray-600" />
                          <Typography className="font-medium text-sm md:text-lg text-gray-600">
                            {item.userJob}
                          </Typography>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <PhoneIcon className="-mt-px h-4 w-4 text-gray-600" />
                          <Typography className="font-medium text-sm md:text-lg text-gray-600">
                            {item.phoneNumber}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex mb-4 items-center justify-center">
                        <a className="mt-1" href={`tel:${item.phoneNumber}`}>
                          <Button
                            size="lg"
                            color="green"
                            variant="gradient"
                            className="flex items-center gap-2"
                          >
                            {defaultLang === "tr"
                              ? "İletİşİme Geçİn"
                              : defaultLang === "fr"
                              ? "Contactez-nous"
                              : defaultLang === "en"
                              ? "Contact"
                              : defaultLang === "de"
                              ? "Kontakt"
                              : null}
                            <PhoneIcon className="h-4 w-4" />
                          </Button>
                        </a>
                      </div>

                      <div className="border-t border-blue-gray-50  text-center">
                        <div className="mt-2 flex flex-wrap justify-center">
                          <div className="flex w-full flex-col items-center px-4 lg:w-9/12">
                            <Typography className=" text-gray-500">
                              {item.description}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className="flex my-10 ">
                        <Swiper
                          modules={[Pagination, A11y]}
                          pagination={{ clickable: true }}
                          spaceBetween={16}
                          slidesPerView={1}
                          className="pb-10"
                          breakpoints={{
                            // When window width is >= 320px
                            320: {
                              slidesPerView: 2,
                              spaceBetween: 50,
                            },
                            // When window width is >= 480px
                            480: {
                              slidesPerView: 3,
                              spaceBetween: 50,
                            },
                            // When window width is >= 768px
                            768: {
                              slidesPerView: 4,
                              spaceBetween: 40,
                            },
                            // When window width is >= 1024px
                            1024: {
                              slidesPerView: 5,
                              spaceBetween: 40,
                            },
                          }}
                        >
                          <SwiperSlide>
                            <Button
                              onClick={() =>
                                handleDownloadClick(
                                  item.phoneNumber,
                                  item.firstName,
                                  item.lastName,
                                  item.userQr,
                                  item.userEmail
                                )
                              }
                              size="lg"
                              color="white"
                              variant="gradient"
                              className="h-40 w-40 flex flex-col items-center  "
                            >
                              <Avatar
                                src="/img/newnumber.png"
                                alt="Profile picture"
                                variant="rounded"
                                className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-orange-400"
                              />

                              <Typography
                                variant="small"
                                className=" font-medium"
                              >
                                {defaultLang === "tr"
                                  ? "Rehbere Ekle"
                                  : defaultLang === "fr"
                                  ? "Ajouter au répertoire"
                                  : defaultLang === "en"
                                  ? "Add to Directory"
                                  : defaultLang === "de"
                                  ? "Zum Verzeıchnıs hınzufugen"
                                  : null}
                              </Typography>
                            </Button>
                          </SwiperSlide>

                          {item.userWebsite != null ? (
                            <SwiperSlide>
                              <Link to={item.userWebsite}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/website.png"
                                    alt="Profile picture"
                                    variant="rounded"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-purple-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    {defaultLang === "tr"
                                      ? "Web Sİtesİ"
                                      : defaultLang === "fr"
                                      ? "Sıte Internet"
                                      : defaultLang === "en"
                                      ? "Websıte"
                                      : defaultLang === "de"
                                      ? "Webseıte"
                                      : null}
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                          <SwiperSlide>
                            <Link to={item.userWhatsapp}>
                              <Button
                                size="lg"
                                color="white"
                                variant="gradient"
                                className="h-40 w-40 flex flex-col items-center  "
                              >
                                <Avatar
                                  src="/img/whatsapp.png"
                                  variant="rounded"
                                  alt="Profile picture"
                                  className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-green-400"
                                />
                                <Typography
                                  variant="small"
                                  className="font-medium"
                                >
                                  Whatsapp
                                </Typography>
                              </Button>
                            </Link>
                          </SwiperSlide>
                          {item.userFacebook != null ? (
                            <SwiperSlide>
                              <Link to={item.userFacebook}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    variant="rounded"
                                    src="/img/facebook.png"
                                    alt="Profile picture"
                                    className="p-5 mb-2 w-24 h-24 object-cover shadow-xl bg-blue-600"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    Facebook
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                          {item.userInstagram != null ? (
                            <SwiperSlide>
                              <Link to={item.userInstagram}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/insta.png"
                                    alt="Profile picture"
                                    variant="rounded"
                                    className="p-5 mb-2 w-24 h-24 object-cover shadow-xl bg-pink-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    Instagram
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                          {item.userLinkedin != null ? (
                            <SwiperSlide>
                              <Link to={item.userLinkedin}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/linkedin.png"
                                    variant="rounded"
                                    alt="Profile picture"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-blue-800"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    Lınkedın
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                          {item.userTelegram != null ? (
                            <SwiperSlide>
                              <Link to={item.userTelegram}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/telegram.png"
                                    variant="rounded"
                                    alt="Profile picture"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-light-blue-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    Telegram
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                          {item.userEmail != null ? (
                            <SwiperSlide>
                              <Button
                                onClick={(event) =>
                                  handleOpenMail(event, item.userEmail)
                                }
                                href="mailto:"
                                size="lg"
                                color="white"
                                variant="gradient"
                                className="h-40 w-40 flex flex-col items-center  "
                              >
                                <Avatar
                                  src="/img/mail.png"
                                  variant="rounded"
                                  alt="Profile picture"
                                  className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-brown-400"
                                />
                                <Typography
                                  variant="small"
                                  className="font-medium"
                                >
                                  {defaultLang === "tr"
                                    ? "E posta"
                                    : defaultLang === "fr"
                                    ? "E-Maıl"
                                    : defaultLang === "en"
                                    ? "Emaıl"
                                    : defaultLang === "de"
                                    ? "Emaıl"
                                    : null}
                                </Typography>
                              </Button>
                            </SwiperSlide>
                          ) : null}

                          {item.userLocation != null ? (
                            <SwiperSlide>
                              <Link to={item.userLocation}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/location.png"
                                    alt="Profile picture"
                                    variant="rounded"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-yellow-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    {defaultLang === "tr"
                                      ? "Konum"
                                      : defaultLang === "fr"
                                      ? "Emplacement"
                                      : defaultLang === "en"
                                      ? "Locatıon"
                                      : defaultLang === "de"
                                      ? "Standort"
                                      : null}
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}

                          {item.userGithub != null ? (
                            <SwiperSlide>
                              <Link to={item.userGithub}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/github.png"
                                    alt="Profile picture"
                                    variant="rounded"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-gray-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    {defaultLang === "tr"
                                      ? "Github"
                                      : defaultLang === "fr"
                                      ? "Github"
                                      : defaultLang === "en"
                                      ? "Github"
                                      : defaultLang === "de"
                                      ? "Github"
                                      : null}
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}

                          {item.userTwitter != null ? (
                            <SwiperSlide>
                              <Link to={item.userTwitter}>
                                <Button
                                  size="lg"
                                  color="white"
                                  variant="gradient"
                                  className="h-40 w-40 flex flex-col items-center  "
                                >
                                  <Avatar
                                    src="/img/twitter.png"
                                    alt="Profile picture"
                                    variant="rounded"
                                    className="p-5 mb-2 w-24 h-24  object-cover shadow-xl bg-blue-400"
                                  />
                                  <Typography
                                    variant="small"
                                    className="font-medium"
                                  >
                                    Twıtter
                                  </Typography>
                                </Button>
                              </Link>
                            </SwiperSlide>
                          ) : null}
                        </Swiper>
                      </div>
                      <div className="bg-blue-gray-50/50">
                        <Footer />
                      </div>
                    </div>
                  </div>
                  <div className="relative flex items-center bg-white w-full min-w-0 flex-col break-words rounded-3xl shadow-xl shadow-gray-500/5">
                    <div className="fixed z-30 bottom-0 flex flex-row  items-center justify-center w-full">
                      {item.userIban1 === null &&
                      item.userIban2 === null ? null : (
                        <div className="flex items-center container justify-center  border-t-2  border-gray-400 bg-white">
                          <Button
                            onClick={openIbanModal}
                            color="white"
                            variant="gradient"
                            className="shadow-xl"
                          >
                            <Typography
                              variant="small"
                              className="flex flex-col text-center  items-center justify-center font-medium text-gray-700"
                            >
                              <CreditCardIcon className="h-5 w-5 text-gray-600" />
                              IBAN
                            </Typography>
                          </Button>
                          <Modal
                            open={IbanModal}
                            onClose={closeIbanModal}
                            className="flex items-center justify-center rounded-3xl"
                          >
                            <div className="bg-white w-80 h-80 flex flex-col justify-center items-center  rounded-xl">
                              {item.userIban1 != null ? (
                                <div className="w-full flex pb-4 flex-col items-center">
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center font-medium text-blue"
                                  >
                                    {item.userBank1}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center font-medium text-black"
                                  >
                                    {item.userIbanName1}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center  text-black"
                                  >
                                    {item.userIban1}
                                  </Typography>
                                  <Button
                                    onClick={(event) =>
                                      handleCopy(event, item.userIban1)
                                    }
                                    color="blue"
                                    variant="gradient"
                                    className="shadow-xl w-[72%]  bg-white "
                                  >
                                    <Typography
                                      variant="small"
                                      className="flex flex-col text-center  items-center justify-center font-medium text-white"
                                    >
                                      {defaultLang === "tr"
                                        ? "IBAN Bilgisini Kopyala"
                                        : defaultLang === "fr"
                                        ? "Copıer les ınformatıons IBAN"
                                        : defaultLang === "en"
                                        ? "Copy IBAN Informatıon"
                                        : defaultLang === "de"
                                        ? "IBAN-Informatıonen kopıeren"
                                        : null}
                                    </Typography>
                                  </Button>
                                </div>
                              ) : null}
                              {item.userIban2 != null ? (
                                <div className="w-full flex pt-2 flex-col items-center">
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center font-medium text-blue"
                                  >
                                    {item.userBank2}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center font-medium text-black"
                                  >
                                    {item.userIbanName2}
                                  </Typography>
                                  <Typography
                                    variant="small"
                                    className="flex flex-col pb-1 text-center  items-center justify-center  text-black"
                                  >
                                    {item.userIban2}
                                  </Typography>
                                  <Button
                                    onClick={(event) =>
                                      handleCopy(event, item.userIban2)
                                    }
                                    color="blue"
                                    variant="gradient"
                                    className="shadow-xl w-[72%]  bg-white "
                                  >
                                    <Typography
                                      variant="small"
                                      className="flex flex-col text-center  items-center justify-center font-medium text-white"
                                    >
                                      {defaultLang === "tr"
                                        ? "IBAN Bilgisini Kopyala"
                                        : defaultLang === "fr"
                                        ? "Copıer les ınformatıons IBAN"
                                        : defaultLang === "en"
                                        ? "Copy IBAN Informatıon"
                                        : defaultLang === "de"
                                        ? "IBAN-Informatıonen kopıeren"
                                        : null}
                                    </Typography>
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </Modal>
                        </div>
                      )}
                      <div className="flex items-center container border-t-2 border-gray-400  justify-center bg-white ">
                        <Button
                          onClick={openQRModal}
                          color="white"
                          variant="gradient"
                          className="shadow-xl "
                        >
                          <Typography
                            variant="small"
                            className="flex flex-col text-center  items-center justify-center font-medium text-gray-700"
                          >
                            <ArrowUpTrayIcon className="h-5 w-5 text-gray-600" />
                            {defaultLang === "tr"
                              ? "Paylaş"
                              : defaultLang === "fr"
                              ? "Partager"
                              : defaultLang === "en"
                              ? "Share"
                              : defaultLang === "de"
                              ? "Aktıe"
                              : null}
                          </Typography>
                        </Button>
                        <Modal
                          open={QRModal}
                          onClose={closeQRModal}
                          className="flex items-center justify-center rounded-3xl"
                        >
                          <div className="bg-white w-80 h-80 flex flex-col justify-center items-center  rounded-xl">
                            <div className="w-full flex pb-4 flex-col items-center">
                              <QRCodeSVG size="200" value={item.userQr} />
                            </div>
                            <div className="w-full flex pt-2 flex-col items-center">
                              <Button
                                onClick={(event) =>
                                  handleCopy(event, item.userQr)
                                }
                                color="blue"
                                variant="gradient"
                                className="shadow-xl w-[72%]  bg-white "
                              >
                                <Typography
                                  variant="small"
                                  className="flex flex-col text-center  items-center justify-center font-medium text-white"
                                >
                                  {defaultLang === "tr"
                                    ? "Profili Paylaş"
                                    : defaultLang === "fr"
                                    ? "Partager le profıl"
                                    : defaultLang === "en"
                                    ? "Share Profıle"
                                    : defaultLang === "de"
                                    ? "Profıl teılen"
                                    : null}
                                </Typography>
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                      <div className="flex items-center  container  break-words border-gray-400 border-t-2 justify-center bg-white">
                        <Button
                          onClick={openSettingsModal}
                          color="white"
                          variant="gradient"
                          className="shadow-xl"
                        >
                          <Typography
                            variant="small"
                            className="flex flex-col text-center  items-center justify-center font-medium text-gray-700"
                          >
                            <WrenchScrewdriverIcon className="h-5 w-5 text-gray-600" />
                            {defaultLang === "tr"
                              ? "Ayarlar"
                              : defaultLang === "fr"
                              ? "Paramètres"
                              : defaultLang === "en"
                              ? "Settıngs"
                              : defaultLang === "de"
                              ? "Eınstellungen"
                              : null}
                          </Typography>
                        </Button>
                        <Modal
                          open={SettingsModal}
                          onClose={closeSettingsModal}
                          className="flex items-center justify-center rounded-3xl"
                        >
                          <div className="bg-white w-80 h-80 flex flex-col justify-center items-center  rounded-xl">
                            <div className="w-full flex pt-2 flex-col gap-4 items-center">
                              <div className="w-72">
                                <Select
                                  label="Select Language"
                                  onChange={handleSelectLang}
                                  value={defaultLang}
                                >
                                  <Option value="tr">Turkish</Option>
                                  <Option value="en">English</Option>
                                  <Option value="fr">French</Option>
                                  <Option value="de">German</Option>
                                </Select>
                              </div>

                              <Button
                                color="blue"
                                onClick={() =>
                                  (window.location.href = `/admin/${userId}`)
                                }
                                variant="gradient"
                              >
                                {defaultLang === "tr"
                                  ? "Bilgileri Güncelle"
                                  : defaultLang === "fr"
                                  ? "Mettre à jour les informations"
                                  : defaultLang === "en"
                                  ? "Update Information"
                                  : defaultLang === "de"
                                  ? "Informationen aktualisieren"
                                  : null}
                              </Button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default Home;
