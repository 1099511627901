import { Typography } from "@material-tailwind/react";

const year = new Date().getFullYear();

export function Footer() {
  const wisit="Wisitcard";
  const Copyright="Copyright © " + year ;
  return (
    <footer className="relative px-4 pt-8 pb-6">
      <div className="container mx-auto">
        <hr className="border-gray-300" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
          <div className="mx-auto w-full px-4 text-center items-center flex flex-row justify-center">
            <Typography
              variant="small"
              className="font-normal text-blue-gray-500"
            >
              {Copyright}
            </Typography>
            <Typography className="ml-1 font-normal text-gray-500">
                |
              </Typography>
            <Typography>
                <a className="ml-1 font-bold text-blue-500" href="https://wisitcard.com/">{wisit}</a>
              </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}



Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
